export default {
  GET_INPUT_EMAILS_REQUEST: 'GET_INPUT_EMAILS_REQUEST',
  GET_INPUT_EMAILS_SUCCESS: 'GET_INPUT_EMAILS_SUCCESS',
  GET_INPUT_EMAILS_FAIL: 'GET_INPUT_EMAILS_FAIL',

  GET_INPUT_EMAIL_REQUEST: 'GET_INPUT_EMAIL_REQUEST',
  GET_INPUT_EMAIL_SUCCESS: 'GET_INPUT_EMAIL_SUCCESS',
  GET_INPUT_EMAIL_FAIL: 'GET_INPUT_EMAIL_FAIL',

  GET_MAIL_DOMAIN_REQUEST: 'GET_MAIL_DOMAIN_REQUEST',
  GET_MAIL_DOMAIN_SUCCESS: 'GET_MAIL_DOMAIN_SUCCESS',
  GET_MAIL_DOMAIN_FAIL: 'GET_MAIL_DOMAIN_FAIL',

  DOWNLOAD_ORIGINAL_ATTACHMENT_REQUEST: 'DOWNLOAD_ORIGINAL_ATTACHMENT_REQUEST',
  DOWNLOAD_ORIGINAL_ATTACHMENT_SUCCESS: 'DOWNLOAD_ORIGINAL_ATTACHMENT_SUCCESS',
  DOWNLOAD_ORIGINAL_ATTACHMENT_FAIL: 'DOWNLOAD_ORIGINAL_ATTACHMENT_FAIL',

  SET_PARAMS: 'SET_PARAMS',
};
