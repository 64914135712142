import { combineReducers } from 'redux'
import { OrderTypes } from '../../action_types'
import { insertItem } from 'src/utils/helpers'
import { arrayToObject, removeItem, updateObjectInArray } from 'src/utils/helpers'

function all(state = {}, action) {
  switch (action.type) {
    case OrderTypes.GET_ORDERS_SUCCESS:
    case OrderTypes.SUPER_ADMIN_GET_ORDERS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) }
    case OrderTypes.GET_ORDER_SUCCESS:
    case OrderTypes.CREATE_ORDER_SUCCESS:
    case OrderTypes.UPDATE_ORDER_SUCCESS:
    case OrderTypes.VERIFY_ORDER_SUCCESS:
    case OrderTypes.REJECT_ORDER_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      }
    case OrderTypes.DELETE_ORDER_SUCCESS:
      const { [action.id]: _, ...newState } = state
      return newState
    default:
      return state
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case OrderTypes.GET_ORDERS_SUCCESS:
      return action.data.results
    case OrderTypes.CREATE_ORDER_SUCCESS:
      return insertItem(state, action.data)
    case OrderTypes.UPDATE_ORDER_SUCCESS:
      return updateObjectInArray(state, action.data)
    case OrderTypes.DELETE_ORDER_SUCCESS:
      return removeItem(state, action.id)
    default:
      return state
  }
}

function superAdminOrders(state = [], action) {
  switch (action.type) {
    case OrderTypes.SUPER_ADMIN_GET_ORDERS_SUCCESS:
      return action.data.results
    case OrderTypes.CREATE_ORDER_SUCCESS:
      return insertItem(state, action.data)
    case OrderTypes.UPDATE_ORDER_SUCCESS:
    case OrderTypes.VERIFY_ORDER_SUCCESS:
    case OrderTypes.REJECT_ORDER_SUCCESS:
      return updateObjectInArray(state, action.data)
    case OrderTypes.DELETE_ORDER_SUCCESS:
      return removeItem(state, action.id)
    default:
      return state
  }
}

function searchedOrders(state = [], action) {
  switch (action.type) {
    case OrderTypes.SEARCH_ORDERS_SUCCESS:
      return action.data.results
    case OrderTypes.CREATE_ORDER_SUCCESS:
      return insertItem(state, action.data)
    case OrderTypes.UPDATE_ORDER_SUCCESS:
      return updateObjectInArray(state, action.data)
    case OrderTypes.DELETE_ORDER_SUCCESS:
      return removeItem(state, action.id)
    default:
      return state
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case OrderTypes.SUPER_ADMIN_GET_ORDERS_SUCCESS:
      // case OrderTypes.GET_ORDERS_SUCCESS:
      const { results, ...rest } = action.data
      return { ...state, ...rest }
    case OrderTypes.CREATE_ORDER_SUCCESS:
      return { ...state, count: state.count + 1 }
    case OrderTypes.DELETE_ORDER_SUCCESS:
      return { ...state, count: state.count - 1 }
    default:
      return state
  }
}

function newestOrder(state = {}, action) {
  switch (action.type) {
    case OrderTypes.GET_NEWEST_ORDER_SUCCESS:
      return action.data.results?.[0] || {}
    default:
      return state
  }
}

function createdOrder(state = {}, action) {
  switch (action.type) {
    case OrderTypes.CREATE_ORDER_SUCCESS:
      return action.data
    default:
      return state
  }
}

function momoOrder(state = {}, action) {
  switch (action.type) {
    case OrderTypes.CREATE_MOMO_ORDER_SUCCESS:
      return action.data
    default:
      return state
  }
}

function vnpayOrder(state = {}, action) {
  switch (action.type) {
    case OrderTypes.CREATE_VNPAY_ORDER_SUCCESS:
      return action.data
    default:
      return state
  }
}

function orderCode(state = '', action) {
  switch (action.type) {
    case OrderTypes.GEN_ORDER_CODE_SUCCESS:
      return action.data.order_code
    default:
      return state
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  searchedOrders,
  newestOrder,
  createdOrder,
  superAdminOrders,
  momoOrder,
  vnpayOrder,
  orderCode,
})
