import { combineReducers } from 'redux'
import { OrderTypes } from '../../action_types'

import { handleRequest, initialRequestState } from './helpers'

function getOrders(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.GET_ORDERS_REQUEST,
    OrderTypes.GET_ORDERS_SUCCESS,
    OrderTypes.GET_ORDERS_FAIL,
    state,
    action
  )
}

function superAdminGetOrders(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.SUPER_ADMIN_GET_ORDERS_REQUEST,
    OrderTypes.SUPER_ADMIN_GET_ORDERS_SUCCESS,
    OrderTypes.SUPER_ADMIN_GET_ORDERS_FAIL,
    state,
    action
  )
}

function verifyOrder(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.VERIFY_ORDER_REQUEST,
    OrderTypes.VERIFY_ORDER_SUCCESS,
    OrderTypes.VERIFY_ORDER_FAIL,
    state,
    action
  )
}

function rejectOrder(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.REJECT_ORDER_REQUEST,
    OrderTypes.REJECT_ORDER_SUCCESS,
    OrderTypes.REJECT_ORDER_FAIL,
    state,
    action
  )
}

function createOrder(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.CREATE_ORDER_REQUEST,
    OrderTypes.CREATE_ORDER_SUCCESS,
    OrderTypes.CREATE_ORDER_FAIL,
    state,
    action
  )
}

function updateOrder(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.UPDATE_ORDER_REQUEST,
    OrderTypes.UPDATE_ORDER_SUCCESS,
    OrderTypes.UPDATE_ORDER_FAIL,
    state,
    action
  )
}

function deleteOrder(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.DELETE_ORDER_REQUEST,
    OrderTypes.DELETE_ORDER_SUCCESS,
    OrderTypes.DELETE_ORDER_FAIL,
    state,
    action
  )
}

function getNewestOrder(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.GET_NEWEST_ORDER_REQUEST,
    OrderTypes.GET_NEWEST_ORDER_SUCCESS,
    OrderTypes.GET_NEWEST_ORDER_FAIL,
    state,
    action
  )
}

function createMomoOrder(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.CREATE_MOMO_ORDER_REQUEST,
    OrderTypes.CREATE_MOMO_ORDER_SUCCESS,
    OrderTypes.CREATE_MOMO_ORDER_FAIL,
    state,
    action
  )
}

function createVnpayOrder(state = initialRequestState(), action) {
  return handleRequest(
    OrderTypes.CREATE_VNPAY_ORDER_REQUEST,
    OrderTypes.CREATE_VNPAY_ORDER_SUCCESS,
    OrderTypes.CREATE_VNPAY_ORDER_FAIL,
    state,
    action
  )
}
export default combineReducers({
  getOrders,
  createOrder,
  updateOrder,
  deleteOrder,
  getNewestOrder,
  superAdminGetOrders,
  verifyOrder,
  rejectOrder,
  createMomoOrder,
  createVnpayOrder,
})
