import { combineReducers } from 'redux';
import { UserTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case UserTypes.GET_USERS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case UserTypes.GET_USER_SUCCESS:
    case UserTypes.CREATE_USER_SUCCESS:
    case UserTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case UserTypes.DELETE_USER_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case UserTypes.GET_USERS_SUCCESS:
      return action.data.results;
    case UserTypes.CREATE_USER_SUCCESS:
      return insertItem(state, action.data);
    case UserTypes.UPDATE_USER_SUCCESS:
      return updateObjectInArray(state, action.data);
    case UserTypes.DELETE_USER_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case UserTypes.GET_USERS_SUCCESS: {
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    }
    case UserTypes.CREATE_USER_SUCCESS:
      return { ...state, total: state.total + 1 };
    case UserTypes.DELETE_USER_SUCCESS:
      return { ...state, total: state.total - 1 };
    default:
      return state;
  }
}

function search(state = [], action) {
  switch (action.type) {
    case UserTypes.SEARCH_USERS_SUCCESS:
      return action.data.results;
    default:
      return state;
  }
}

function myProfileImage(state = null, action) {
  switch (action.type) {
    case UserTypes.UPLOAD_MY_PROFILE_IMAGE_SUCCESS:
      return action.image;
    case UserTypes.GET_MY_PROFILE_IMAGE_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function images(state = {}, action) {
  switch (action.type) {
    case UserTypes.GET_USER_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        [action.id]: action.data,
      };
    default:
      return state;
  }
}
export default combineReducers({
  all,
  ui,
  meta,
  search,
  myProfileImage,
  images,
});
