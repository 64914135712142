export default {
  GET_REGISTRATIONS_REQUEST: 'GET_REGISTRATIONS_REQUEST',
  GET_REGISTRATIONS_SUCCESS: 'GET_REGISTRATIONS_SUCCESS',
  GET_REGISTRATIONS_FAIL: 'GET_REGISTRATIONS_FAIL',

  GET_REGISTRATION_REQUEST: 'GET_REGISTRATION_REQUEST',
  GET_REGISTRATION_SUCCESS: 'GET_REGISTRATION_SUCCESS',
  GET_REGISTRATION_FAIL: 'GET_REGISTRATION_FAIL',

  APPROVE_REGISTRATION_REQUEST: 'APPROVE_REGISTRATION_REQUEST',
  APPROVE_REGISTRATION_SUCCESS: 'APPROVE_REGISTRATION_SUCCESS',
  APPROVE_REGISTRATION_FAIL: 'APPROVE_REGISTRATION_FAIL',

  DECLINE_REGISTRATION_REQUEST: 'DECLINE_REGISTRATION_REQUEST',
  DECLINE_REGISTRATION_SUCCESS: 'DECLINE_REGISTRATION_SUCCESS',
  DECLINE_REGISTRATION_FAIL: 'DECLINE_REGISTRATION_FAIL',

  ACTIVATE_REGISTRATION_REQUEST: 'ACTIVATE_REGISTRATION_REQUEST',
  ACTIVATE_REGISTRATION_SUCCESS: 'ACTIVATE_REGISTRATION_SUCCESS',
  ACTIVATE_REGISTRATION_FAIL: 'ACTIVATE_REGISTRATION_FAIL',

  DEACTIVATE_REGISTRATION_REQUEST: 'DEACTIVATE_REGISTRATION_REQUEST',
  DEACTIVATE_REGISTRATION_SUCCESS: 'DEACTIVATE_REGISTRATION_SUCCESS',
  DEACTIVATE_REGISTRATION_FAIL: 'DEACTIVATE_REGISTRATION_FAIL',

  ADD_REGISTRATION_REQUEST: 'ADD_REGISTRATION_REQUEST',
  ADD_REGISTRATION_SUCCESS: 'ADD_REGISTRATION_SUCCESS',
  ADD_REGISTRATION_FAIL: 'ADD_REGISTRATION_FAIL',

  UPDATE_USER_REGISTER_REQUEST: 'UPDATE_USER_REGISTER_REQUEST',
  UPDATE_USER_REGISTER_SUCCESS: 'UPDATE_USER_REGISTER_SUCCESS',
  UPDATE_USER_REGISTER_FAIL: 'UPDATE_USER_REGISTER_FAIL',

  UPDATE_ORG_REGISTER_REQUEST: 'UPDATE_ORG_REGISTER_REQUEST',
  UPDATE_ORG_REGISTER_SUCCESS: 'UPDATE_ORG_REGISTER_SUCCESS',
  UPDATE_ORG_REGISTER_FAIL: 'UPDATE_ORG_REGISTER_FAIL',

  RESEND_APPROVE_REQUEST: 'RESEND_APPROVE_REQUEST',
  RESEND_APPROVE_SUCCESS: 'RESEND_APPROVE_SUCCESS',
  RESEND_APPROVE_FAIL: 'RESEND_APPROVE_FAIL',
};
