import { combineReducers } from 'redux';
import { AccountTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getAccounts(state = initialRequestState(), action) {
  return handleRequest(
    AccountTypes.GET_ACCOUNTS_REQUEST,
    AccountTypes.GET_ACCOUNTS_SUCCESS,
    AccountTypes.GET_ACCOUNTS_FAIL,
    state,
    action
  );
}

function updateAccount(state = initialRequestState(), action) {
  return handleRequest(
    AccountTypes.UPDATE_ACCOUNT_REQUEST,
    AccountTypes.UPDATE_ACCOUNT_SUCCESS,
    AccountTypes.UPDATE_ACCOUNT_FAIL,
    state,
    action
  );
}

function deleteAccount(state = initialRequestState(), action) {
  return handleRequest(
    AccountTypes.DELETE_ACCOUNT_REQUEST,
    AccountTypes.DELETE_ACCOUNT_SUCCESS,
    AccountTypes.DELETE_ACCOUNT_FAIL,
    state,
    action
  );
}

function inviteUsers(state = initialRequestState(), action) {
  return handleRequest(
    AccountTypes.INVITE_USERS_REQUEST,
    AccountTypes.INVITE_USERS_SUCCESS,
    AccountTypes.INVITE_USERS_FAIL,
    state,
    action
  );
}

function reinviteUser(state = initialRequestState(), action) {
  return handleRequest(
    AccountTypes.REINVITE_USER_REQUEST,
    AccountTypes.REINVITE_USER_SUCCESS,
    AccountTypes.REINVITE_USER_FAIL,
    state,
    action
  );
}

function checkAccount(state = initialRequestState(), action) {
  return handleRequest(
    AccountTypes.CHECK_ACCOUNT_REQUEST,
    AccountTypes.CHECK_ACCOUNT_SUCCESS,
    AccountTypes.CHECK_ACCOUNT_FAIL,
    state,
    action
  );
}

function acceptInvite(state = initialRequestState(), action) {
  return handleRequest(
    AccountTypes.ACCEPT_INVITE_REQUEST,
    AccountTypes.ACCEPT_INVITE_SUCCESS,
    AccountTypes.ACCEPT_INVITE_FAIL,
    state,
    action
  );
}

function createUserByInvitation(state = initialRequestState(), action) {
  return handleRequest(
    AccountTypes.CREATE_USER_BY_INVITATION_REQUEST,
    AccountTypes.CREATE_USER_BY_INVITATION_SUCCESS,
    AccountTypes.CREATE_USER_BY_INVITATION_FAIL,
    state,
    action
  );
}

function changeRole(state = initialRequestState(), action) {
  return handleRequest(
    AccountTypes.CHANGE_ROLE_REQUEST,
    AccountTypes.CHANGE_ROLE_SUCCESS,
    AccountTypes.CHANGE_ROLE_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getAccounts,
  updateAccount,
  deleteAccount,
  inviteUsers,
  reinviteUser,
  checkAccount,
  acceptInvite,
  createUserByInvitation,
  changeRole,
});
