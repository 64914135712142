import { combineReducers } from 'redux';
import { SubmissionTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';
import { toObject } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    // case SubmissionTypes.GET_SUBMISSIONS_SUCCESS:
    //   return { ...state, ...arrayToObject(action.data.results) };
    case SubmissionTypes.GET_SUBMISSION_SUCCESS:
    case SubmissionTypes.UPDATE_SUBMISSION_SUCCESS:
    case SubmissionTypes.VALIDATE_SUBMISSION_SUCCESS:
    case SubmissionTypes.VERIFY_SUBMISSION_SUCCESS:
    // case SubmissionTypes.REJECT_SUBMISSION_SUCCESS:
    case SubmissionTypes.ASSIGN_SUBMISSION_TO_VALIDATOR_SUCCESS:
    case SubmissionTypes.SYNC_BV_CARE_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };

    default:
      return state;
  }
}

function list(state = {}, action) {
  // chỉ dùng cho selector getErrorSubmissionIds
  switch (action.type) {
    case SubmissionTypes.GET_SUBMISSIONS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case SubmissionTypes.GET_SUBMISSION_SUCCESS:
    case SubmissionTypes.UPDATE_SUBMISSION_SUCCESS:
    // case SubmissionTypes.VALIDATE_SUBMISSION_SUCCESS:
    // case SubmissionTypes.VERIFY_SUBMISSION_SUCCESS:
    // case SubmissionTypes.REJECT_SUBMISSION_SUCCESS:
    case SubmissionTypes.ASSIGN_SUBMISSION_TO_VALIDATOR_SUCCESS:
    case SubmissionTypes.SYNC_BV_CARE_SUCCESS:
    case SubmissionTypes.GET_SUBMISSION_BY_SOCKET_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };

    default:
      return state;
  }
}

function fields(state = {}, action) {
  switch (action.type) {
    case SubmissionTypes.GET_SUBMISSION_FIELD_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data.submission_data_set,
      };
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case SubmissionTypes.GET_SUBMISSIONS_SUCCESS:
      return action.data.results;
    case SubmissionTypes.UPDATE_SUBMISSION_SUCCESS:
    // case SubmissionTypes.VALIDATE_SUBMISSION_SUCCESS:
    // case SubmissionTypes.VERIFY_SUBMISSION_SUCCESS:
    case SubmissionTypes.REJECT_SUBMISSION_SUCCESS:
    case SubmissionTypes.ASSIGN_SUBMISSION_TO_VALIDATOR_SUCCESS:
    case SubmissionTypes.SYNC_BV_CARE_SUCCESS:
    case SubmissionTypes.GET_SUBMISSION_SUCCESS:
      return updateObjectInArray(state, action.data);
    case SubmissionTypes.GET_SUBMISSION_BY_SOCKET_SUCCESS: {
      const { currentStatus, data } = action;
      if (!currentStatus) {
        return updateObjectInArray(state, action.data);
      }
      if (currentStatus.includes(data.status)) {
        let tmp = state.filter(s => s.id === data.id);
        if (tmp.length > 0) {
          return updateObjectInArray(state, data);
        }
        return insertItem(state, data);
      }

      if (!currentStatus.includes(data.status)) {
        return removeItem(state, action.data.id);
      }
      return state;
    }
    // case SubmissionTypes.VALIDATE_SUBMISSION_SUCCESS:
    // case SubmissionTypes.VERIFY_SUBMISSION_SUCCESS:
    case SubmissionTypes.REMOVE_SUBMISSION_FROM_LIST:
      return removeItem(state, action.data.id);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case SubmissionTypes.GET_SUBMISSIONS_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };

    default:
      return state;
  }
}

function thumb(state = {}, action) {
  switch (action.type) {
    case SubmissionTypes.GET_SUBMISSION_THUMB_SUCCESS:
      return { ...state, [action.id]: action.data };
    default:
      return state;
  }
}

function submissionsNumber(state = {}, action) {
  switch (action.type) {
    case SubmissionTypes.GET_SUBMISSIONS_NUMBER_SUCCESS:
      return { ...state, ...toObject(action.data, 'status') };
    default:
      return state;
  }
}

function currentDocuments(state = [], action) {
  switch (action.type) {
    case SubmissionTypes.SET_CURRENT_DOCUMENTS:
      return action.data;
    default:
      return state;
  }
}

function mapDocumentType(state = {}, action) {
  switch (action.type) {
    case SubmissionTypes.SET_MAP_DOCUMENT_TYPE:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

function originColumns(state = {}, action) {
  switch (action.type) {
    case SubmissionTypes.SET_ORIGIN_COLUMNS:
      return action.data;
    default:
      return state;
  }
}

function originOrdered(state = {}, action) {
  switch (action.type) {
    case SubmissionTypes.SET_ORIGIN_ORDERED:
      return action.data;
    default:
      return state;
  }
}

function sortedColumns(state = {}, action) {
  switch (action.type) {
    case SubmissionTypes.SET_SORTED_COLUMNS:
      return action.data;
    default:
      return state;
  }
}

function orderedColumns(state = [], action) {
  switch (action.type) {
    case SubmissionTypes.SET_ORDERED:
      return action.data;
    default:
      return state;
  }
}

function oldId(state = '', action) {
  switch (action.type) {
    case SubmissionTypes.SET_OLD_ID:
      return action.data;
    default:
      return state;
  }
}

function newId(state = '', action) {
  switch (action.type) {
    case SubmissionTypes.SET_NEW_ID:
      return action.data;
    default:
      return state;
  }
}

function tempColumns(state = null, action) {
  switch (action.type) {
    case SubmissionTypes.SET_TEMP_COLUMNS:
      return action.data;
    default:
      return state;
  }
}

function tempOrdered(state = null, action) {
  switch (action.type) {
    case SubmissionTypes.SET_TEMP_ORDERED:
      return action.data;
    default:
      return state;
  }
}

function BVCareUsers(state = [], action) {
  switch (action.type) {
    case SubmissionTypes.GET_BVCARE_USERS_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function submissionsToAssign(state = {}, action) {
  switch (action.type) {
    case SubmissionTypes.SET_SUBMISSIONS_TO_ASSIGN:
      return action.data;
    default:
      return state;
  }
}

function assignResult(state = [], action) {
  switch (action.type) {
    case SubmissionTypes.SET_ASSIGN_RESULT:
      return action.data;
    default:
      return state;
  }
}

function showActiveBox(state = true, action) {
  switch (action.type) {
    case SubmissionTypes.SET_SHOW_ACTIVE_BOX:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  all,
  list,
  fields,
  ui,
  meta,
  thumb,
  submissionsNumber,
  currentDocuments,
  mapDocumentType,
  originColumns,
  originOrdered,
  sortedColumns,
  orderedColumns,
  oldId,
  newId,
  tempColumns,
  tempOrdered,
  BVCareUsers,
  submissionsToAssign,
  assignResult,
  showActiveBox,
});
