import { combineReducers } from 'redux';
import { SubmissionTypeTypes } from '../../action_types';
import { arrayToObject, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case SubmissionTypeTypes.GET_SUBMISSION_TYPES_SUCCESS:
    case SubmissionTypeTypes.GET_ORG_SUBMISSION_TYPES_SUCCESS:
    case SubmissionTypeTypes.SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_SUCCESS:
      return arrayToObject(action.data);
    case SubmissionTypeTypes.GET_SUBMISSION_TYPE_SUCCESS:
    case SubmissionTypeTypes.UPDATE_ORG_SUBMISSION_TYPE_SUCCESS:
    case SubmissionTypeTypes.SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_SUCCESS:
    case SubmissionTypeTypes.UPDATE_SUBMISSION_TYPE_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    default:
      return state;
  }
}

function list(state = [], action) {
  switch (action.type) {
    case SubmissionTypeTypes.GET_SUBMISSION_TYPES_SUCCESS:
      return action.data;
    case SubmissionTypeTypes.UPDATE_SUBMISSION_TYPE_SUCCESS:
      return updateObjectInArray(state, action.data);
    default:
      return state;
  }
}

function org(state = [], action) {
  switch (action.type) {
    case SubmissionTypeTypes.GET_ORG_SUBMISSION_TYPES_SUCCESS:
    case SubmissionTypeTypes.SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_SUCCESS:
      return action.data;
    case SubmissionTypeTypes.UPDATE_ORG_SUBMISSION_TYPE_SUCCESS:
    case SubmissionTypeTypes.SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_SUCCESS:
      return updateObjectInArray(state, action.data);
    default:
      return state;
  }
}

function icons(state = {}, action) {
  switch (action.type) {
    case SubmissionTypeTypes.GET_ICON_SUBMISSION_TYPE_SUCCESS:
      return {
        ...state,
        [action.id]: action.data,
      };
    case SubmissionTypeTypes.UPLOAD_ICON_SUBMISSION_TYPE_SUCCESS:
      return {
        ...state,
        [action.id]: action.image,
      };
    default:
      return state;
  }
}
export default combineReducers({
  all,
  list,
  org,
  icons,
});
