export default {
  GET_BV_DOCUMENT_REQUEST: 'GET_BV_DOCUMENT_REQUEST',
  GET_BV_DOCUMENT_SUCCESS: 'GET_BV_DOCUMENT_SUCCESS',
  GET_BV_DOCUMENT_FAIL: 'GET_BV_DOCUMENT_FAIL',

  VALIDATE_DOCUMENT_REQUEST: 'VALIDATE_DOCUMENT_REQUEST',
  VALIDATE_DOCUMENT_SUCCESS: 'VALIDATE_DOCUMENT_SUCCESS',
  VALIDATE_DOCUMENT_FAIL: 'VALIDATE_DOCUMENT_FAIL',

  VERIFY_DOCUMENT_REQUEST: 'VERIFY_DOCUMENT_REQUEST',
  VERIFY_DOCUMENT_SUCCESS: 'VERIFY_DOCUMENT_SUCCESS',
  VERIFY_DOCUMENT_FAIL: 'VERIFY_DOCUMENT_FAIL',

  REJECT_DOCUMENT_REQUEST: 'REJECT_DOCUMENT_REQUEST',
  REJECT_DOCUMENT_SUCCESS: 'REJECT_DOCUMENT_SUCCESS',
  REJECT_DOCUMENT_FAIL: 'REJECT_DOCUMENT_FAIL',

  RESET_DOCUMENT_REQUEST: 'RESET_DOCUMENT_REQUEST',
  RESET_DOCUMENT_SUCCESS: 'RESET_DOCUMENT_SUCCESS',
  RESET_DOCUMENT_FAIL: 'RESET_DOCUMENT_FAIL',

  CREATE_DOCUMENT_REQUEST: 'CREATE_DOCUMENT_REQUEST',
  CREATE_DOCUMENT_SUCCESS: 'CREATE_DOCUMENT_SUCCESS',
  CREATE_DOCUMENT_FAIL: 'CREATE_DOCUMENT_FAIL',

  SET_DATAPOINTS: 'SET_DATAPOINTS',

  CLEAR_DOCUMENT: 'CLEAR_DOCUMENT',

  PREVIEW_DOCUMENT_REQUEST: 'PREVIEW_DOCUMENT_REQUEST',
  PREVIEW_DOCUMENT_SUCCESS: 'PREVIEW_DOCUMENT_SUCCESS',
  PREVIEW_DOCUMENT_FAIL: 'PREVIEW_DOCUMENT_FAIL',

  PREVIEW_IMAGE_REQUEST: 'PREVIEW_IMAGE_REQUEST',
  PREVIEW_IMAGE_SUCCESS: 'PREVIEW_IMAGE_SUCCESS',
  PREVIEW_IMAGE_FAIL: 'PREVIEW_IMAGE_FAIL',

  SET_FOCUSED_CELL_ID: 'SET_FOCUSED_CELL_ID',
};
