import { combineReducers } from 'redux';
import { AccountTypes, RegistrationTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case RegistrationTypes.GET_REGISTRATIONS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case RegistrationTypes.APPROVE_REGISTRATION_SUCCESS:
    case RegistrationTypes.ACTIVATE_REGISTRATION_SUCCESS:
    case RegistrationTypes.ADD_REGISTRATION_SUCCESS:
    case RegistrationTypes.DECLINE_REGISTRATION_SUCCESS:
    case RegistrationTypes.DEACTIVATE_REGISTRATION_SUCCESS:
    case RegistrationTypes.UPDATE_USER_REGISTER_SUCCESS:
    case RegistrationTypes.GET_REGISTRATION_SUCCESS:
    case RegistrationTypes.RESEND_APPROVE_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case RegistrationTypes.UPDATE_ORG_REGISTER_SUCCESS:
      return {
        ...state,
        [action.user.id]: {
          ...action.user,
          org: action.data,
        },
      };
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case RegistrationTypes.GET_REGISTRATIONS_SUCCESS:
      return action.data.results;
    case RegistrationTypes.APPROVE_REGISTRATION_SUCCESS:
    case RegistrationTypes.ACTIVATE_REGISTRATION_SUCCESS:
    case RegistrationTypes.DECLINE_REGISTRATION_SUCCESS:
    case RegistrationTypes.DEACTIVATE_REGISTRATION_SUCCESS:
    case RegistrationTypes.UPDATE_USER_REGISTER_SUCCESS:
    case RegistrationTypes.RESEND_APPROVE_SUCCESS: {
      return updateObjectInArray(state, action.data);
    }
    case RegistrationTypes.ADD_REGISTRATION_SUCCESS:
    case RegistrationTypes.UPDATE_USER_REGISTER_SUCCESS:
      return insertItem(state, action.data);
    case RegistrationTypes.UPDATE_ORG_REGISTER_SUCCESS: {
      const obj = {
        ...action.user,
        org: action.data,
      };
      return updateObjectInArray(state, obj);
    }
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case RegistrationTypes.GET_REGISTRATIONS_SUCCESS: {
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    }
    case RegistrationTypes.ADD_REGISTRATION_SUCCESS:
    case AccountTypes.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        count: state.count + 1,
      };
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
});
