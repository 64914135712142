import { combineReducers } from 'redux';
import { TransactionTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function createTransaction(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.CREATE_TRANSACTION_REQUEST,
    TransactionTypes.CREATE_TRANSACTION_SUCCESS,
    TransactionTypes.CREATE_TRANSACTION_FAIL,
    state,
    action
  );
}

function getTransactions(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.GET_TRANSACTIONS_REQUEST,
    TransactionTypes.GET_TRANSACTIONS_SUCCESS,
    TransactionTypes.GET_TRANSACTIONS_FAIL,
    state,
    action
  );
}

function getTransaction(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.GET_TRANSACTION_REQUEST,
    TransactionTypes.GET_TRANSACTION_SUCCESS,
    TransactionTypes.GET_TRANSACTION_FAIL,
    state,
    action
  );
}

function payForTransaction(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.PAY_FOR_TRANSACTION_REQUEST,
    TransactionTypes.PAY_FOR_TRANSACTION_SUCCESS,
    TransactionTypes.PAY_FOR_TRANSACTION_FAIL,
    state,
    action
  );
}

function cancelTransaction(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.CANCEL_TRANSACTION_REQUEST,
    TransactionTypes.CANCEL_TRANSACTION_SUCCESS,
    TransactionTypes.CANCEL_TRANSACTION_FAIL,
    state,
    action
  );
}

function getPricingInfo(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.GET_PRICING_INFO_REQUEST,
    TransactionTypes.GET_PRICING_INFO_SUCCESS,
    TransactionTypes.GET_PRICING_INFO_FAIL,
    state,
    action
  );
}

function getSystemBankingInfo(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.GET_SYSTEM_BANKING_INFO_REQUEST,
    TransactionTypes.GET_SYSTEM_BANKING_INFO_SUCCESS,
    TransactionTypes.GET_SYSTEM_BANKING_INFO_FAIL,
    state,
    action
  );
}

function superAdminCancelTransaction(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.SUPER_ADMIN_CANCEL_TRANSACTION_REQUEST,
    TransactionTypes.SUPER_ADMIN_CANCEL_TRANSACTION_SUCCESS,
    TransactionTypes.SUPER_ADMIN_CANCEL_TRANSACTION_FAIL,
    state,
    action
  );
}

function superAdminValidateTransaction(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.SUPER_ADMIN_VALIDATE_TRANSACTION_REQUEST,
    TransactionTypes.SUPER_ADMIN_VALIDATE_TRANSACTION_SUCCESS,
    TransactionTypes.SUPER_ADMIN_VALIDATE_TRANSACTION_FAIL,
    state,
    action
  );
}

function superAdminGetTransactions(state = initialRequestState(), action) {
  return handleRequest(
    TransactionTypes.SUPER_ADMIN_GET_TRANSACTIONS_REQUEST,
    TransactionTypes.SUPER_ADMIN_GET_TRANSACTIONS_SUCCESS,
    TransactionTypes.SUPER_ADMIN_GET_TRANSACTIONS_FAIL,
    state,
    action
  );
}

export default combineReducers({
  createTransaction,
  getPricingInfo,
  getSystemBankingInfo,
  getTransaction,
  getTransactions,
  payForTransaction,
  cancelTransaction,
  superAdminCancelTransaction,
  superAdminGetTransactions,
  superAdminValidateTransaction,
});
