import { combineReducers } from 'redux';
import { SubmissionTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getSubmission(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.GET_SUBMISSION_REQUEST,
    SubmissionTypes.GET_SUBMISSION_SUCCESS,
    SubmissionTypes.GET_SUBMISSION_FAIL,
    state,
    action
  );
}

function getSubmissions(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.GET_SUBMISSIONS_REQUEST,
    SubmissionTypes.GET_SUBMISSIONS_SUCCESS,
    SubmissionTypes.GET_SUBMISSIONS_FAIL,
    state,
    action
  );
}

function updateSubmission(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.UPDATE_SUBMISSION_REQUEST,
    SubmissionTypes.UPDATE_SUBMISSION_SUCCESS,
    SubmissionTypes.UPDATE_SUBMISSION_FAIL,
    state,
    action
  );
}

function getBVPageImage(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.GET_BV_PAGE_IMAGE_REQUEST,
    SubmissionTypes.GET_BV_PAGE_IMAGE_SUCCESS,
    SubmissionTypes.GET_BV_PAGE_IMAGE_FAIL,
    state,
    action
  );
}

function uploadSubmission(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.UPLOAD_SUBMISSION_REQUEST,
    SubmissionTypes.UPLOAD_SUBMISSION_SUCCESS,
    SubmissionTypes.UPDATE_SUBMISSION_FAIL,
    state,
    action
  );
}

function deleteSubmissions(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.DELETE_SUBMISSIONS_REQUEST,
    SubmissionTypes.DELETE_SUBMISSIONS_SUCCESS,
    SubmissionTypes.DELETE_SUBMISSIONS_FAIL,
    state,
    action
  );
}

function validateSubmission(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.VALIDATE_SUBMISSION_REQUEST,
    SubmissionTypes.VALIDATE_SUBMISSION_SUCCESS,
    SubmissionTypes.VALIDATE_SUBMISSION_FAIL,
    state,
    action
  );
}
function verifySubmission(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.VERIFY_SUBMISSION_REQUEST,
    SubmissionTypes.VERIFY_SUBMISSION_SUCCESS,
    SubmissionTypes.VERIFY_SUBMISSION_FAIL,
    state,
    action
  );
}
function rejectSubmission(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.REJECT_SUBMISSION_REQUEST,
    SubmissionTypes.REJECT_SUBMISSION_SUCCESS,
    SubmissionTypes.REJECT_SUBMISSION_FAIL,
    state,
    action
  );
}

function assignSubmission(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.ASSIGN_SUBMISSION_TO_VALIDATOR_REQUEST,
    SubmissionTypes.ASSIGN_SUBMISSION_TO_VALIDATOR_SUCCESS,
    SubmissionTypes.ASSIGN_SUBMISSION_TO_VALIDATOR_FAIL,
    state,
    action
  );
}

function moveSubmissions(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.MOVE_SUBMISSIONS_REQUEST,
    SubmissionTypes.MOVE_SUBMISSIONS_SUCCESS,
    SubmissionTypes.MOVE_SUBMISSIONS_FAIL,
    state,
    action
  );
}

function retryOCRSubmissions(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.RETRY_OCR_SUBMISSIONS_REQUEST,
    SubmissionTypes.RETRY_OCR_SUBMISSIONS_SUCCESS,
    SubmissionTypes.RETRY_OCR_SUBMISSIONS_FAIL,
    state,
    action
  );
}

function getSubmissionField(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.GET_SUBMISSION_FIELD_REQUEST,
    SubmissionTypes.GET_SUBMISSION_FIELD_SUCCESS,
    SubmissionTypes.GET_SUBMISSION_FIELD_FAIL,
    state,
    action
  );
}

function exportSubmissions(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypes.EXPORT_SUBMISSIONS_REQUEST,
    SubmissionTypes.EXPORT_SUBMISSIONS_SUCCESS,
    SubmissionTypes.EXPORT_SUBMISSIONS_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getSubmission,
  getSubmissions,
  updateSubmission,
  getBVPageImage,
  uploadSubmission,
  deleteSubmissions,
  validateSubmission,
  verifySubmission,
  rejectSubmission,
  assignSubmission,
  moveSubmissions,
  retryOCRSubmissions,
  getSubmissionField,
  exportSubmissions,
});
