import { combineReducers } from 'redux';
import { BillingTypes } from '../../action_types';
import { insertItem } from 'src/utils/helpers';
import moment from 'moment';

function used_count(state = [], action) {
  switch (action.type) {
    case BillingTypes.GET_USED_COUNT_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function submission_type(state = [], action) {
  switch (action.type) {
    case BillingTypes.GET_SUBMISSION_TYPE_COUNT_SUCCESS:
      return action.data.sort((a, b) => b.total - a.total);
    default:
      return state;
  }
}

function used_total(state = 0, action) {
  switch (action.type) {
    case BillingTypes.GET_SUBMISSION_TYPE_COUNT_SUCCESS:
      let total = 0;
      action.data.map(c => {
        total += c.total;
      });
      return total;
    default:
      return state;
  }
}

function categories(state = [], action) {
  switch (action.type) {
    case BillingTypes.GET_USED_COUNT_SUCCESS: {
      if (action.year) {
        return action.data.map(a => {
          return `Thg ${a.month.split('-')[1]}`;
        });
      }
      return action.data.map(a => {
        return `${a.date.split('T')[0].split('-')[2]}`;
      });
    }
    default:
      return state;
  }
}

export default combineReducers({
  used_count,
  used_total,
  submission_type,
  categories,
});
