import { combineReducers } from 'redux';
import { TransactionTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case TransactionTypes.SUPER_ADMIN_GET_TRANSACTIONS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case TransactionTypes.SUPER_ADMIN_VALIDATE_TRANSACTION_SUCCESS:
    case TransactionTypes.SUPER_ADMIN_CANCEL_TRANSACTION_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case TransactionTypes.SUPER_ADMIN_GET_TRANSACTIONS_SUCCESS:
      return action.data.results;
    case TransactionTypes.SUPER_ADMIN_VALIDATE_TRANSACTION_SUCCESS:
    case TransactionTypes.SUPER_ADMIN_CANCEL_TRANSACTION_SUCCESS:
      return updateObjectInArray(state, action.data);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case TransactionTypes.SUPER_ADMIN_GET_TRANSACTIONS_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
});
