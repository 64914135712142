export default {
  GET_PRICING_INFO_REQUEST: 'GET_PRICING_INFO_REQUEST',
  GET_PRICING_INFO_SUCCESS: 'GET_PRICING_INFO_SUCCESS',
  GET_PRICING_INFO_FAIL: 'GET_PRICING_INFO_FAIL',

  CREATE_TRANSACTION_REQUEST: 'CREATE_TRANSACTION_REQUEST',
  CREATE_TRANSACTION_SUCCESS: 'CREATE_TRANSACTION_SUCCESS',
  CREATE_TRANSACTION_FAIL: 'CREATE_TRANSACTION_FAIL',

  GET_TRANSACTIONS_REQUEST: 'GET_TRANSACTIONS_REQUEST',
  GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAIL: 'GET_TRANSACTIONS_FAIL',

  GET_TRANSACTION_REQUEST: 'GET_TRANSACTION_REQUEST',
  GET_TRANSACTION_SUCCESS: 'GET_TRANSACTION_SUCCESS',
  GET_TRANSACTION_FAIL: 'GET_TRANSACTION_FAIL',

  PAY_FOR_TRANSACTION_REQUEST: 'PAY_FOR_TRANSACTION_REQUEST',
  PAY_FOR_TRANSACTION_SUCCESS: 'PAY_FOR_TRANSACTION_SUCCESS',
  PAY_FOR_TRANSACTION_FAIL: 'PAY_FOR_TRANSACTION_FAIL',

  CANCEL_TRANSACTION_REQUEST: 'CANCEL_TRANSACTION_REQUEST',
  CANCEL_TRANSACTION_SUCCESS: 'CANCEL_TRANSACTION_SUCCESS',
  CANCEL_TRANSACTION_FAIL: 'CANCEL_TRANSACTION_FAIL',

  GET_SYSTEM_BANKING_INFO_REQUEST: 'GET_SYSTEM_BANKING_INFO_REQUEST',
  GET_SYSTEM_BANKING_INFO_SUCCESS: 'GET_SYSTEM_BANKING_INFO_SUCCESS',
  GET_SYSTEM_BANKING_INFO_FAIL: 'GET_SYSTEM_BANKING_INFO_FAIL',

  SUPER_ADMIN_CANCEL_TRANSACTION_REQUEST: 'SUPER_ADMIN_CANCEL_TRANSACTION_REQUEST',
  SUPER_ADMIN_CANCEL_TRANSACTION_SUCCESS: 'SUPER_ADMIN_CANCEL_TRANSACTION_SUCCESS',
  SUPER_ADMIN_CANCEL_TRANSACTION_FAIL: 'SUPER_ADMIN_CANCEL_TRANSACTION_FAIL',

  SUPER_ADMIN_VALIDATE_TRANSACTION_REQUEST: 'SUPER_ADMIN_VALIDATE_TRANSACTION_REQUEST',
  SUPER_ADMIN_VALIDATE_TRANSACTION_SUCCESS: 'SUPER_ADMIN_VALIDATE_TRANSACTION_SUCCESS',
  SUPER_ADMIN_VALIDATE_TRANSACTION_FAIL: 'SUPER_ADMIN_VALIDATE_TRANSACTION_FAIL',

  SUPER_ADMIN_GET_TRANSACTIONS_REQUEST: 'SUPER_ADMIN_GET_TRANSACTIONS_REQUEST',
  SUPER_ADMIN_GET_TRANSACTIONS_SUCCESS: 'SUPER_ADMIN_GET_TRANSACTIONS_SUCCESS',
  SUPER_ADMIN_GET_TRANSACTIONS_FAIL: 'SUPER_ADMIN_GET_TRANSACTIONS_FAIL',
};
