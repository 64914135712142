import React from 'react'
import { Provider } from 'react-redux'
import GlobalStyles from '@app/assets/styles/globalStyle'
import { store, persistor } from './redux/store'
import Boot from './redux/boot'
import Routes from './router'
import AppProvider from './AppProvider'
import { hot } from 'react-hot-loader/root'
import { PersistGate } from 'redux-persist/integration/react'
import { GoogleOAuthProvider } from '@react-oauth/google'

const App = () => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppProvider>
          <>
            <GlobalStyles />
            <Routes />
          </>
        </AppProvider>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
)

Boot()
  .then(() => App())
  .catch(error => console.error(error))

export default process.env.NODE_ENV === 'development' ? hot(App) : App
