import { combineReducers } from 'redux';
import { OrgTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getOrg(state = initialRequestState(), action) {
  return handleRequest(OrgTypes.GET_ORG_REQUEST, OrgTypes.GET_ORG_SUCCESS, OrgTypes.GET_ORG_FAIL, state, action);
}

function createOrg(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.CREATE_ORG_REQUEST,
    OrgTypes.CREATE_ORG_SUCCESS,
    OrgTypes.CREATE_ORG_FAIL,
    state,
    action
  );
}

function updateOrg(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.UPDATE_ORG_REQUEST,
    OrgTypes.UPDATE_ORG_SUCCESS,
    OrgTypes.UPDATE_ORG_FAIL,
    state,
    action
  );
}

function deleteOrg(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.DELETE_ORG_REQUEST,
    OrgTypes.DELETE_ORG_SUCCESS,
    OrgTypes.DELETE_ORG_FAIL,
    state,
    action
  );
}

function getDocumentTypes(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.GET_DOCUMENT_TYPES_REQUEST,
    OrgTypes.GET_DOCUMENT_TYPES_SUCCESS,
    OrgTypes.GET_DOCUMENT_TYPES_FAIL,
    state,
    action
  );
}

function getDocumentType(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.GET_DOCUMENT_TYPE_REQUEST,
    OrgTypes.GET_DOCUMENT_TYPE_SUCCESS,
    OrgTypes.GET_DOCUMENT_TYPE_FAIL,
    state,
    action
  );
}

function updateDocumentType(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.UPDATE_DOCUMENT_TYPE_REQUEST,
    OrgTypes.UPDATE_DOCUMENT_TYPE_SUCCESS,
    OrgTypes.UPDATE_DOCUMENT_TYPE_FAIL,
    state,
    action
  );
}

function createDocumentType(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.CREATE_DOCUMENT_TYPE_REQUEST,
    OrgTypes.CREATE_DOCUMENT_TYPE_SUCCESS,
    OrgTypes.CREATE_DOCUMENT_TYPE_FAIL,
    state,
    action
  );
}

function deleteDocumentType(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.DELETE_DOCUMENT_TYPE_REQUEST,
    OrgTypes.DELETE_DOCUMENT_TYPE_SUCCESS,
    OrgTypes.DELETE_DOCUMENT_TYPE_FAIL,
    state,
    action
  );
}

function getDocumentAnalytics(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.GET_DOCUMENT_ANALYTICS_REQUEST,
    OrgTypes.GET_DOCUMENT_ANALYTICS_SUCCESS,
    OrgTypes.GET_DOCUMENT_ANALYTICS_FAIL,
    state,
    action
  );
}

function leaveOrg(state = initialRequestState(), action) {
  return handleRequest(OrgTypes.LEAVE_ORG_REQUEST, OrgTypes.LEAVE_ORG_SUCCESS, OrgTypes.LEAVE_ORG_FAIL, state, action);
}

function uploadOrgProfileImage(state = initialRequestState(), action) {
  return handleRequest(
    OrgTypes.UPLOAD_ORG_PROFILE_IMAGE_REQUEST,
    OrgTypes.UPLOAD_ORG_PROFILE_IMAGE_SUCCESS,
    OrgTypes.UPLOAD_ORG_PROFILE_IMAGE_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getOrg,
  createOrg,
  updateOrg,
  deleteOrg,
  leaveOrg,
  getDocumentType,
  getDocumentTypes,
  updateDocumentType,
  getDocumentAnalytics,
  createDocumentType,
  deleteDocumentType,
  uploadOrgProfileImage,
});
