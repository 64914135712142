import { combineReducers } from 'redux';
import { OrgTypes, AuthTypes, AccountTypes, RegistrationTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';
import { getCurrentRole, setCurrentRole } from 'src/library/helpers/utility';

function all(state = {}, action) {
  switch (action.type) {
    case OrgTypes.GET_ORGS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case OrgTypes.GET_ORG_SUCCESS:
    case OrgTypes.CREATE_ORG_SUCCESS:
    case OrgTypes.UPDATE_ORG_SUCCESS:
    case RegistrationTypes.UPDATE_ORG_REGISTER_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case OrgTypes.DELETE_ORG_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case OrgTypes.GET_ORGS_SUCCESS:
      return action.data.results;
    case OrgTypes.CREATE_ORG_SUCCESS:
      return insertItem(state, action.data);
    case OrgTypes.UPDATE_ORG_SUCCESS:
      return updateObjectInArray(state, action.data);
    case OrgTypes.DELETE_ORG_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case OrgTypes.GET_ORGS_SUCCESS: {
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    }
    default:
      return state;
  }
}

const status = ['processing', 'for_review', 'for_verify', 'rejected'];
function dashboard(state = {}, action) {
  switch (action.type) {
    case OrgTypes.GET_DOCUMENT_ANALYTICS_SUCCESS: {
      const obj = {};
      let total = 0;
      action.data.status_count.map(e => {
        obj[e.status] = e.total;
        if (status.indexOf(e.status) !== -1) {
          total += e.total;
        }
      });
      return {
        ...action.data,
        status_count: obj,
        total,
      };
    }
    default:
      return state;
  }
}

function currentOrgRole(state = {}, action) {
  switch (action.type) {
    case AuthTypes.LOGIN_SUCCESS:
    case AuthTypes.GET_ME_SUCCESS:
    case AuthTypes.UPDATE_ME_SUCCESS: {
      const { org_member_roles } = action.data;
      const hasOrg = org_member_roles.length > 0;
      const oldOrg = org_member_roles.find(org_role => org_role.id === state.id);
      return oldOrg ? oldOrg : hasOrg ? { ...state, ...org_member_roles[0] } : {};

      // const currentRole = getCurrentRole();
      // if (!currentRole || !currentRole.id) {
      //   const { org_member_roles } = action.data;
      //   const hasOrg = org_member_roles.length > 0;
      //   const oldOrg = org_member_roles.find(org_role => org_role.id === state.id);
      //   const role = oldOrg ? oldOrg : hasOrg ? { ...state, ...org_member_roles[0] } : {};
      //   setCurrentRole(role);

      //   return role;
      // }
      // return currentRole;
    }
    case AccountTypes.ACCEPT_INVITE_SUCCESS: {
      const { org, user } = action.data;
      if (org) {
        const { org_member_roles } = user;
        const role = org_member_roles.find(org_role => org_role.org.id === org);
        setCurrentRole(role);
        return role ? role : state;
      }
      return state;
    }
    case AuthTypes.CHANGE_ORG_ROLE:
      setCurrentRole(action.data);
      return { ...state, ...action.data };
    default:
      return state;
  }
}

function images(state = {}, action) {
  switch (action.type) {
    case OrgTypes.GET_ORG_PROFILE_IMAGE_SUCCESS:
      return { ...state, [action.id]: action.data };
    case OrgTypes.UPLOAD_ORG_PROFILE_IMAGE_SUCCESS:
      return { ...state, [action.id]: action.image };
    default:
      return state;
  }
}

function credit_balance(state = -1, action) {
  switch (action.type) {
    case OrgTypes.GET_CREDIT_BALANCE_SUCCESS:
      return action.data.credit_balance;
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  dashboard,
  currentOrgRole,
  images,
  credit_balance,
});
