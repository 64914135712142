import { combineReducers } from 'redux';
import { Fie, FieldDataTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getSubmissionField(state = initialRequestState(), action) {
  return handleRequest(
    FieldDataTypes.GET_SUBMISSION_FIELD_REQUEST,
    FieldDataTypes.GET_SUBMISSION_FIELD_SUCCESS,
    FieldDataTypes.GET_SUBMISSION_FIELD_FAIL,
    state,
    action
  );
}

function getFieldData(state = initialRequestState(), action) {
  return handleRequest(
    FieldDataTypes.GET_FIELD_DATA_REQUEST,
    FieldDataTypes.GET_FIELD_DATA_SUCCESS,
    FieldDataTypes.GET_FIELD_DATA_FAIL,
    state,
    action
  );
}

function addFieldRow(state = initialRequestState(), action) {
  return handleRequest(
    FieldDataTypes.ADD_FIELD_ROW_REQUEST,
    FieldDataTypes.ADD_FIELD_ROW_SUCCESS,
    FieldDataTypes.ADD_FIELD_ROW_FAIL,
    state,
    action
  );
}

function addFieldColumn(state = initialRequestState(), action) {
  return handleRequest(
    FieldDataTypes.ADD_FIELD_COLUMN_REQUEST,
    FieldDataTypes.ADD_FIELD_COLUMN_SUCCESS,
    FieldDataTypes.ADD_FIELD_COLUMN_FAIL,
    state,
    action
  );
}

function deleteFieldRow(state = initialRequestState(), action) {
  return handleRequest(
    FieldDataTypes.DELETE_FIELD_ROW_REQUEST,
    FieldDataTypes.DELETE_FIELD_ROW_SUCCESS,
    FieldDataTypes.DELETE_FIELD_ROW_FAIL,
    state,
    action
  );
}

function deleteFieldColumn(state = initialRequestState(), action) {
  return handleRequest(
    FieldDataTypes.DELETE_FIELD_COLUMN_REQUEST,
    FieldDataTypes.DELETE_FIELD_COLUMN_SUCCESS,
    FieldDataTypes.DELETE_FIELD_COLUMN_FAIL,
    state,
    action
  );
}

function updateFieldData(state = initialRequestState(), action) {
  return handleRequest(
    FieldDataTypes.UPDATE_FIELD_DATA_REQUEST,
    FieldDataTypes.UPDATE_FIELD_DATA_SUCCESS,
    FieldDataTypes.UPDATE_FIELD_DATA_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getSubmissionField,
  getFieldData,
  addFieldRow,
  addFieldColumn,
  deleteFieldRow,
  updateFieldData,
  deleteFieldColumn,
});
