import { combineReducers } from 'redux';
import { TransactionTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case TransactionTypes.GET_TRANSACTIONS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case TransactionTypes.GET_TRANSACTION_SUCCESS:
    case TransactionTypes.CREATE_TRANSACTION_SUCCESS:
    case TransactionTypes.CANCEL_TRANSACTION_SUCCESS:
    case TransactionTypes.PAY_FOR_TRANSACTION_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case TransactionTypes.GET_TRANSACTIONS_SUCCESS:
      return action.data.results;
    case TransactionTypes.CREATE_TRANSACTION_SUCCESS:
      return insertItem(state, action.data);
    case TransactionTypes.CANCEL_TRANSACTION_SUCCESS:
    case TransactionTypes.PAY_FOR_TRANSACTION_SUCCESS: {
      return updateObjectInArray(state, action.data);
    }
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case TransactionTypes.GET_TRANSACTIONS_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    default:
      return state;
  }
}

function transaction(state = {}, action) {
  switch (action.type) {
    case TransactionTypes.GET_TRANSACTION_SUCCESS:
      return action.data;
    case TransactionTypes.CREATE_TRANSACTION_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function pricing_info(state = {}, action) {
  switch (action.type) {
    case TransactionTypes.GET_PRICING_INFO_SUCCESS:
      const { range } = action.data.price_range;
      const obj = {};
      range.map(r => {
        const { min, max, reduced_percent } = r;
        if (min === 0) {
          return;
        }
        const key = min ? min : max;
        obj[key] = reduced_percent;
      });
      return {
        ...action.data,
        price_range: obj,
      };
    default:
      return state;
  }
}

function bank_info(state = [], action) {
  switch (action.type) {
    case TransactionTypes.GET_SYSTEM_BANKING_INFO_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  pricing_info,
  bank_info,
  transaction,
});
