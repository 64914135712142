export default {
  GET_ACCOUNTS_REQUEST: 'GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAIL: 'GET_ACCOUNTS_FAIL',

  INVITE_USERS_REQUEST: 'INVITE_USERS_REQUEST',
  INVITE_USERS_SUCCESS: 'INVITE_USERS_SUCCESS',
  INVITE_USERS_FAIL: 'INVITE_USERS_FAIL',

  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAIL: 'DELETE_ACCOUNT_FAIL',

  UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAIL: 'UPDATE_ACCOUNT_FAIL',

  GET_ACCOUNT_REQUEST: 'GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS: 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAIL: 'GET_ACCOUNT_FAIL',

  REINVITE_USER_REQUEST: 'REINVITE_USER_REQUEST',
  REINVITE_USER_SUCCESS: 'REINVITE_USER_SUCCESS',
  REINVITE_USER_FAIL: 'REINVITE_USER_FAIL',

  CHECK_ACCOUNT_REQUEST: 'CHECK_ACCOUNT_REQUEST',
  CHECK_ACCOUNT_SUCCESS: 'CHECK_ACCOUNT_SUCCESS',
  CHECK_ACCOUNT_FAIL: 'CHECK_ACCOUNT_FAIL',

  ACCEPT_INVITE_REQUEST: 'ACCEPT_INVITE_REQUEST',
  ACCEPT_INVITE_SUCCESS: 'ACCEPT_INVITE_SUCCESS',
  ACCEPT_INVITE_FAIL: 'ACCEPT_INVITE_FAIL',

  CREATE_USER_BY_INVITATION_REQUEST: 'CREATE_USER_BY_INVITATION_REQUEST',
  CREATE_USER_BY_INVITATION_SUCCESS: 'CREATE_USER_BY_INVITATION_SUCCESS',
  CREATE_USER_BY_INVITATION_FAIL: 'CREATE_USER_BY_INVITATION_FAIL',

  CHANGE_ROLE_REQUEST: 'CHANGE_ROLE_REQUEST',
  CHANGE_ROLE_SUCCESS: 'CHANGE_ROLE_SUCCESS',
  CHANGE_ROLE_FAIL: 'CHANGE_ROLE_FAIL',
};
