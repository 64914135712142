import { combineReducers } from 'redux';
import { RegistrationTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getRegistrations(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.GET_REGISTRATIONS_REQUEST,
    RegistrationTypes.GET_REGISTRATIONS_SUCCESS,
    RegistrationTypes.GET_REGISTRATIONS_FAIL,
    state,
    action
  );
}

function approveRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.APPROVE_REGISTRATION_REQUEST,
    RegistrationTypes.APPROVE_REGISTRATION_SUCCESS,
    RegistrationTypes.APPROVE_REGISTRATION_FAIL,
    state,
    action
  );
}

function declineRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.DECLINE_REGISTRATION_REQUEST,
    RegistrationTypes.DECLINE_REGISTRATION_SUCCESS,
    RegistrationTypes.DECLINE_REGISTRATION_FAIL,
    state,
    action
  );
}

function activateRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.ACTIVATE_REGISTRATION_REQUEST,
    RegistrationTypes.ACTIVATE_REGISTRATION_SUCCESS,
    RegistrationTypes.ACTIVATE_REGISTRATION_FAIL,
    state,
    action
  );
}

function deactivateRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.DEACTIVATE_REGISTRATION_REQUEST,
    RegistrationTypes.DEACTIVATE_REGISTRATION_SUCCESS,
    RegistrationTypes.DEACTIVATE_REGISTRATION_FAIL,
    state,
    action
  );
}

function createRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.ADD_REGISTRATION_REQUEST,
    RegistrationTypes.ADD_REGISTRATION_SUCCESS,
    RegistrationTypes.ADD_REGISTRATION_FAIL,
    state,
    action
  );
}

function updateUserRegister(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.UPDATE_USER_REGISTER_REQUEST,
    RegistrationTypes.UPDATE_USER_REGISTER_SUCCESS,
    RegistrationTypes.UPDATE_USER_REGISTER_FAIL,
    state,
    action
  );
}

function updateOrgRegister(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.UPDATE_ORG_REGISTER_REQUEST,
    RegistrationTypes.UPDATE_ORG_REGISTER_SUCCESS,
    RegistrationTypes.UPDATE_ORG_REGISTER_FAIL,
    state,
    action
  );
}

function resendApprove(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.RESEND_APPROVE_REQUEST,
    RegistrationTypes.RESEND_APPROVE_SUCCESS,
    RegistrationTypes.RESEND_APPROVE_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getRegistrations,
  approveRegistration,
  declineRegistration,
  activateRegistration,
  deactivateRegistration,
  createRegistration,
  updateUserRegister,
  updateOrgRegister,
  resendApprove,
});
