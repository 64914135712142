export function initialRequestState() {
  return {
    status: 'not_started',
    error: null,
  };
}

export function handleRequest(REQUEST, SUCCESS, FAILURE, state, action) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        status: 'started',
      };
    case SUCCESS:
      return {
        ...state,
        status: 'success',
        error: null,
      };
    case FAILURE: {
      return {
        ...state,
        status: 'failure',
        error: action.error,
      };
    }
    default:
      return state;
    // return {
    //   status: 'not_started',
    //   error: null,
    // };
  }
}
