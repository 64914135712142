import { combineReducers } from 'redux';
import { DatapointTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getDatapoint(state = initialRequestState(), action) {
  return handleRequest(
    DatapointTypes.GET_DATAPOINT_REQUEST,
    DatapointTypes.GET_DATAPOINT_SUCCESS,
    DatapointTypes.GET_DATAPOINT_FAIL,
    state,
    action
  );
}

function addRow(state = initialRequestState(), action) {
  return handleRequest(
    DatapointTypes.ADD_ROW_REQUEST,
    DatapointTypes.ADD_ROW_SUCCESS,
    DatapointTypes.ADD_ROW_FAIL,
    state,
    action
  );
}

function deleteRow(state = initialRequestState(), action) {
  return handleRequest(
    DatapointTypes.DELETE_ROW_REQUEST,
    DatapointTypes.DELETE_ROW_SUCCESS,
    DatapointTypes.DELETE_ROW_FAIL,
    state,
    action
  );
}

function updateDatapoint(state = initialRequestState(), action) {
  return handleRequest(
    DatapointTypes.UPDATE_DATAPOINT_REQUEST,
    DatapointTypes.UPDATE_DATAPOINT_SUCCESS,
    DatapointTypes.UPDATE_DATAPOINT_FAIL,
    state,
    action
  );
}

function validateDatapoint(state = initialRequestState(), action) {
  return handleRequest(
    DatapointTypes.VALIDATE_DATAPOINT_REQUEST,
    DatapointTypes.VALIDATE_DATAPOINT_SUCCESS,
    DatapointTypes.VALIDATE_DATAPOINT_FAIL,
    state,
    action
  );
}

function verifyDatapoint(state = initialRequestState(), action) {
  return handleRequest(
    DatapointTypes.VERIFY_DATAPOINT_REQUEST,
    DatapointTypes.VERIFY_DATAPOINT_SUCCESS,
    DatapointTypes.VERIFY_DATAPOINT_FAIL,
    state,
    action
  );
}

function rejectDatapoint(state = initialRequestState(), action) {
  return handleRequest(
    DatapointTypes.REJECT_DATAPOINT_REQUEST,
    DatapointTypes.REJECT_DATAPOINT_SUCCESS,
    DatapointTypes.REJECT_DATAPOINT_FAIL,
    state,
    action
  );
}

function addColumn(state = initialRequestState(), action) {
  return handleRequest(
    DatapointTypes.ADD_COLUMN_REQUEST,
    DatapointTypes.ADD_COLUMN_SUCCESS,
    DatapointTypes.ADD_COLUMN_FAIL,
    state,
    action
  );
}

function deleteColumn(state = initialRequestState(), action) {
  return handleRequest(
    DatapointTypes.DELETE_COLUMN_REQUEST,
    DatapointTypes.DELETE_COLUMN_SUCCESS,
    DatapointTypes.DELETE_COLUMN_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getDatapoint,
  addRow,
  deleteRow,
  updateDatapoint,
  validateDatapoint,
  verifyDatapoint,
  rejectDatapoint,
  addColumn,
  deleteColumn,
});
