import { DefaultServerUrl } from '../assets/config.json'
import Auth from '../components/Auth'
import { setAuthorization, setBaseURL } from './docbase/client/axiosConfig'

export default () => {
  if (Auth.isLoggedIn()) {
    return new Promise(() => {
      setAuthorization(Auth.isLoggedIn())
      setBaseURL(DefaultServerUrl)
    })
  }
  return new Promise(() => {
    setBaseURL(DefaultServerUrl)
  })
}
