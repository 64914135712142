import { combineReducers } from 'redux';
import { PaymentTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getPayments(state = initialRequestState(), action) {
  return handleRequest(
    PaymentTypes.GET_PAYMENTS_REQUEST,
    PaymentTypes.GET_PAYMENTS_SUCCESS,
    PaymentTypes.GET_PAYMENTS_FAIL,
    state,
    action
  );
}

function getNewestPayment(state = initialRequestState(), action) {
  return handleRequest(
    PaymentTypes.GET_NEWEST_PAYMENT_REQUEST,
    PaymentTypes.GET_NEWEST_PAYMENT_SUCCESS,
    PaymentTypes.GET_NEWEST_PAYMENT_FAIL,
    state,
    action
  );
}

function createPayment(state = initialRequestState(), action) {
  return handleRequest(
    PaymentTypes.CREATE_PAYMENT_REQUEST,
    PaymentTypes.CREATE_PAYMENT_SUCCESS,
    PaymentTypes.CREATE_PAYMENT_FAIL,
    state,
    action
  );
}

function updatePayment(state = initialRequestState(), action) {
  return handleRequest(
    PaymentTypes.UPDATE_PAYMENT_REQUEST,
    PaymentTypes.UPDATE_PAYMENT_SUCCESS,
    PaymentTypes.UPDATE_PAYMENT_FAIL,
    state,
    action
  );
}

function deletePayment(state = initialRequestState(), action) {
  return handleRequest(
    PaymentTypes.DELETE_PAYMENT_REQUEST,
    PaymentTypes.DELETE_PAYMENT_SUCCESS,
    PaymentTypes.DELETE_PAYMENT_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getPayments,
  createPayment,
  updatePayment,
  deletePayment,
  getNewestPayment,
});
