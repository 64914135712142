export const PUBLIC_ROUTE = {
  LANDING: '/login',
  SIGN_IN: '/login',
  SIGN_UP: '/signup',
  SIGN_UP_SUCCESS: '/signup-success',
  CREATE_PASSWORD: '/create-password',
  FORGET_PASSWORD: '/forgot-password',
  SIGN_UP_COMPLETE: '/signup-complete',
  CREATE_ORG: '/create-organization',
  CREATE_ORG_SUCCESS: '/create-organization-success',
  JOIN_ORG: '/join-org',
  CREATE_ACCOUNT: '/create-account',
  PAGE_403: '/403',
  PAGE_404: '/404',
  PAGE_500: '/500',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_SUCCESS: '/reset-password-success',
}
