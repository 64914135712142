import { combineReducers } from 'redux';
import { PaymentTypes } from '../../action_types';
import { insertItem } from 'src/utils/helpers';
import { arrayToObject, removeItem, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case PaymentTypes.GET_PAYMENTS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case PaymentTypes.GET_PAYMENT_SUCCESS:
    case PaymentTypes.CREATE_PAYMENT_SUCCESS:
    case PaymentTypes.UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case PaymentTypes.DELETE_PAYMENT_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case PaymentTypes.GET_PAYMENTS_SUCCESS:
      return action.data.results;
    case PaymentTypes.CREATE_PAYMENT_SUCCESS:
      return insertItem(state, action.data);
    case PaymentTypes.UPDATE_PAYMENT_SUCCESS:
      return updateObjectInArray(state, action.data);
    case PaymentTypes.DELETE_PAYMENT_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case PaymentTypes.GET_PAYMENTS_SUCCESS: {
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    }
    case PaymentTypes.CREATE_PAYMENT_SUCCESS:
      return { ...state, count: state.count + 1 };
    case PaymentTypes.DELETE_PAYMENT_SUCCESS:
      return { ...state, count: state.count - 1 };
    default:
      return state;
  }
}

function newestPayment(state = [], action) {
  switch (action.type) {
    case PaymentTypes.GET_NEWEST_PAYMENT_SUCCESS:
      return action.data.results;
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  newestPayment,
});
