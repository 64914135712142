import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './app';
import * as serviceWorker from './serviceWorker';
import numbro from 'numbro';
import deDe from 'numbro/languages/de-DE';

// numbro.registerLanguage(deDe);

numbro.registerLanguage({
  languageTag: 'de-DE',
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function () {
    return '.';
  },
  spaceSeparated: true,
  currency: {
    symbol: '',
    position: 'postfix',
    code: 'EUR',
  },
  currencyFormat: {
    // totalLength: 4,
    thousandSeparated: true,
    mantissa: 2,
    spaceSeparated: true,
  },
  formats: {
    fourDigits: {
      totalLength: 4,
      spaceSeparated: true,
      average: true,
    },
    fullWithTwoDecimals: {
      output: 'currency',
      mantissa: 2,
      spaceSeparated: true,
      thousandSeparated: true,
    },
    fullWithTwoDecimalsNoCurrency: {
      mantissa: 2,
      thousandSeparated: true,
    },
    fullWithNoDecimals: {
      output: 'currency',
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 0,
    },
  },
});

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://fcca11f34d2e422abd1439b8b8b0ddaf@o1027618.ingest.sentry.io/5994422',
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
